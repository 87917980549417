import type { FC, MouseEvent } from 'react';
import React, { useEffect, Fragment } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import { styled } from '@compiled/react';

import { IconButton } from '@atlaskit/button/new';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { N50, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { PositionType } from '@atlaskit/tooltip';
import Tooltip from '@atlaskit/tooltip';

import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { ErrorDisplay } from '@confluence/error-boundary';
import type {
	SpaceOperationsQueryType,
	SpaceOperationsQueryVariables,
} from '@confluence/entity-operations';
import { SpaceOperationsQuery } from '@confluence/entity-operations';
import { ShortcutVisualizer } from '@confluence/shortcuts';
import { useRouteActions } from '@confluence/route-manager';

export type Props = {
	canCreateContent: (permissions: SpaceOperationsQueryType) => boolean;
	onCreateContent?: ({ spaceId }: { spaceId: string }) => void;
	href?: string;
	labelI18n: MessageDescriptor;
	tooltipShortcut?: string;
	tooltipTestId?: string;
	spaceKey: string;
	tooltipPosition: PositionType | undefined;
	createPagePermissionCheck?: (canCreate: boolean | undefined) => (() => void) | undefined;
	pagesSideNavButtonStyleOverride?: boolean;
	testId?: string;
};

// This is changing the color only when the mouse pointer hovers over the icon
// itself. If you hover over the outskirts of the button, it'll darken but the
// icon won't. That's likely not intentional.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LightGreyIconWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			color: token('color.icon.subtle', N50),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&:hover svg': {
			color: token('color.icon', N500),
		},
	},
});

export const CreateButtonBase: FC<Props> = ({
	canCreateContent,
	href,
	onCreateContent,
	tooltipTestId,
	labelI18n,
	tooltipShortcut,
	spaceKey,
	createPagePermissionCheck,
	tooltipPosition,
	pagesSideNavButtonStyleOverride = false,
	testId,
}) => {
	const intl = useIntl();
	const { data, loading, error } = useQuery<
		SpaceOperationsQueryType,
		SpaceOperationsQueryVariables
	>(SpaceOperationsQuery, { variables: { spaceKey } });
	// Storage Enforcement - #cc-onboarding
	const { enforceStorageLimit } = useHardStorageEnforcement({
		source: 'page-tree-create-button-base',
	});
	const { push } = useRouteActions();

	const canCreate = data && canCreateContent(data);

	useEffect(() => createPagePermissionCheck?.(canCreate), [createPagePermissionCheck, canCreate]);

	if (error) {
		return <ErrorDisplay error={error} />;
	}

	if (loading || !canCreate) {
		return null;
	}

	const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		event.preventDefault();

		if (href) {
			if (onCreateContent) {
				const spaceId = data?.space?.id || '';
				onCreateContent({ spaceId });
			}

			if (event.metaKey) {
				window.open(href, '_blank', 'noreferrer');
			} else {
				push(href);
			}
		}
	};

	const tooltipContent = tooltipShortcut ? (
		<ShortcutVisualizer
			shortcut={tooltipShortcut}
			contentBefore={<FormattedMessage {...labelI18n} />}
		/>
	) : (
		intl.formatMessage(labelI18n)
	);

	const IconWrapper = pagesSideNavButtonStyleOverride ? LightGreyIconWrapper : Fragment;

	return (
		<Tooltip content={tooltipContent} position={tooltipPosition} testId={tooltipTestId}>
			<IconWrapper>
				<IconButton
					icon={EditorAddIcon}
					label={intl.formatMessage(labelI18n)}
					onClick={enforceStorageLimit(handleButtonClick)}
					testId={testId}
					spacing="compact"
					appearance="subtle"
					isDisabled={Boolean(process.env.REACT_SSR)}
				/>
			</IconWrapper>
		</Tooltip>
	);
};
