import { LoadableLazy } from '@confluence/loadable';

export const ADD_SMART_LINK_EMBED_FORM_ONBOARDING_QUERY_PARAM = 'isSmartLinkEmbedOnboardingActive';

export { AddSmartLinkEmbedFormSpotlightTarget } from './AddSmartLinkEmbedFormSpotlightTarget';

export const AddSmartLinkEmbedFormSpotlight = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AddSmartLinkEmbedFormSpotlight" */ './AddSmartLinkEmbedFormSpotlight'
			)
		).AddSmartLinkEmbedFormSpotlight,
});

export { CreateSmartLinkEmbedPopoverItemSpotlight } from './CreateSmartLinkEmbedPopoverItemSpotlight';
export type { CreateSmartLinkEmbedPopoverItemSpotlightProps } from './CreateSmartLinkEmbedPopoverItemSpotlight';

export const SmartLinkEmbedOnboardingModalManager = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SmartLinkEmbedOnboardingModalManager" */ './SmartLinkEmbedOnboardingModalManager'
			)
		).SmartLinkEmbedOnboardingModalManager,
});

export { SCREEN_NAME } from './SmartLinkEmbedOnboardingModal';

export {
	useGlobalCreateSpotlightPulseState,
	useSmartLinkEmbedOnboardingActions,
} from './useSmartLinkEmbedOnboardingState';
